import { cva } from 'class-variance-authority'

export { default as ButtonGroup } from './ButtonGroup.vue'

export const buttonGroupVariants = cva('flex h-fit w-fit flex-none', {
  variants: {
    gap: {
      true: 'gap-x-2',
      false: ''
    },
    outline: {
      true: '',
      false: ''
    }
  },
  compoundVariants: [
    {
      gap: false,
      outline: true,
      class: 'divide-x divide-slate-300 rounded-lg outline outline-1 outline-slate-300'
    }
  ]
})

export const buttonGroupChild = cva('', {
  variants: {
    gap: {
      true: '',
      false: 'ring-0'
    },
    outline: {
      true: '',
      false: 'ring-0'
    }
  },
  compoundVariants: [
    {
      gap: false,
      outline: true,
      class: 'rounded-none first:rounded-l-lg last:rounded-r-lg'
    }
  ]
})
