<template>
  <slot />
</template>

<script lang="ts">
import { cloneVNode } from 'vue'
import { buttonGroupVariants, buttonGroupChild } from '.'
import type { ButtonGroupProps } from './types'

export default {
  props: {
    size: String as PropType<ButtonGroupProps['size']>,
    gap: Boolean,
    outline: Boolean
  },
  setup(props) {
    return function () {
      let slotContent = this.$slots.default?.() || []
      if (Array.isArray(slotContent[0].children)) {
        slotContent = slotContent[0].children
      }
      return h(
        'div',
        { class: buttonGroupVariants({ gap: props.gap, outline: props.outline }) },
        slotContent.map((vnode) =>
          cloneVNode(vnode, {
            size: props.size,
            class: buttonGroupChild({ outline: props.outline, gap: props.gap })
          })
        )
      )
    }
  }
}
</script>
